    
.logo {
    position: fixed;
    z-index: 999;
    top: var(--logo-spacing);
    left: var(--logo-spacing);
    padding: var(--logo-spacing);
    width: var(--logo-width);
    height: var(--logo-height);  
    @include media-queries(medium){
      position: static;
      width: var(--header-height);
      height: var(--header-height);
      grid-area: logo;
      background-color: transparent;
      border-radius: 0;
    }
  }
