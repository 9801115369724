.u-responsive-grid {
    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
  
  .u-responsive-flexbox {
    display: flex;
    flex-wrap: wrap;
  
    & > * { flex: 1 1 200px; }
  }
  .u-message {
    font-family: $roboto-sans;
    font-size: 4vw;
    font-weight:300;
    text-align: center;
  
    @include media-queries(medium) { font-size: 1.5rem; }
  }
  .u-none{
    display: none;
  }
  .u-btn {
    margin: 1rem auto;
    padding: .5rem 1rem;
    outline: 0;
    border: 0;
    font-family: $roboto-sans;
    border-radius:50px;
    background-color: rgba(183, 0, 255, 0.75);
    font-size: 23px;
    color: #fff;
    font-weight: 300;
    cursor: pointer;
    transition: all .3s ease;
  
    &:hover { background-color: var(--alternate-color); }
  }
  .main-grid{
    margin-top: 49px;
  }
  .l-container{
    max-width: $max-width;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}
.l-section{
   margin-bottom: 2rem;
   @include media-queries(large){
       margin-bottom: 4rem;
   }
   &--diagonal{
       position: relative;
       &:before{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: var(--z-back);
        background-color: #ff95f3;
        background: linear-gradient(150deg,#7e00ef 15%,#f7d4ff61 70%,#ffffff57 94%);
        transform: skewY(-1deg) scaleY(1.05);
        @include media-queries(large){
            transform: skewY(-1.4deg) scaleY(1.1);
        }
        
       }
   } 
}
.section-titles{
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.section-title{
  font-family: $roboto-sans;
  font-size: 49px;
  font-weight: 300;
}
.section-description{
  font-family: $roboto-sans;
  text-align: justify;
  font-size: 17px;}
.center-block{
    margin-left:auto;
    margin-right: auto;
}
.text{
text-align: justify;
font-size: 15px;
font-family: $roboto-sans;  
}
@for $i from 1 through 20 {
    .small-#{$i*5}{
        width: #{$i*5%};
    }
}
@include media-queries(large){
    @for $i from 1 through 20 {
        .large-#{$i*5}{
            width: #{$i*5%};
        }
    }
}
.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7e00ef;
  margin-top: 15px;
  padding: 5px;
    &-title{
      font-weight: 300;
      color:#fff;
      font-size: 15px;
    }
    &-year{
      font-weight: 300;
      color:#fff;
      font-size: 15px;
    }
}