.Form {
  * { margin-bottom: .5rem; }

  input[type="text"],
  input[type="email"],
  textarea {
    padding: .5rem;
    width: 100%;
    display: block;
    border: thin solid var(--alternate-color);
    font-size: 1rem;
    font-family:$roboto-sans;
    transition: all .3s ease-out;
    padding: .5rem;
    width: 100%;
    height: 40px;
    border-radius: 30px;
    font-family:$roboto-sans;
    transition: all .3s ease-out;
    outline: none;
  }

  input[type="submit"] {
    @extend .u-message;
    @extend .u-btn;
  }

  progress {
    margin-bottom: 1rem;
    -webkit-appearance: none;
    appearance: none;
    border: 0;
    width: 100%;
    height: 1.5rem;

    &[value] { background-color: var(--alternate-color); }

    &::-webkit-progress-bar { background-color: var(--alternate-color); }

    &::-moz-progress-bar { background-color: var(--second-color); }

    &::-webkit-progress-value { background-color: var(--second-color); }

    + span {
      @extend .u-message;
      display: block;
    }
  }

  &-uploader {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      padding-left: .5rem;
      width: 15%;
      display: block;
      text-align: center;
    }

     input[type="file"] {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      cursor: pointer;
      opacity: 0;
     }
  }
}
.container-contact{
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
  margin: auto;
  border-radius: 10px;
}