
.menu{
    margin-bottom: 2rem;    
    text-align: right;
    margin-bottom: 2rem;
    @include media-queries(small){
        font-size:2vw;
        color:#fff;
    }
    @include media-queries(medium){
        font-size:1.2vw;
        grid-area: menu;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        align-content: center;
        margin-bottom: 0;
    }
    ul{
        @include media-queries(medium){
            margin-bottom: 0;
            align-items: center;
            align-content: space-between;
            display: flex;
        }
        @include media-queries(large){
            // display:initial;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
        }
    }
    li{list-style: none;}
    .admin{
        color:var(--color-admin);
    }
    a{
        padding-right: .5rem;
        display: block;
            &:hover{
                color: var(--main-color);
                background-color: var(--alternate-color);
                @include media-queries(extra-small){
                    background-color: transparent;
                    color:#fff;
                    color:var(--color-hover);
                    font-size: 24px;
                }
                @include media-queries(small){
                    background-color: transparent;
                    color:#fff;
                    color:var(--color-hover);
                    font-size: 24px;
                }
                @include media-queries(medium){
                    background-color: transparent;
                    color:#fff;
                    color:var(--color-hover);
                }
                @include media-queries(medium){
                    background-color: transparent;
                    color:var(--color-hover);
                }
                @include media-queries(large){
                    background-color: transparent;
                }
            }

    }
}

@media (max-width: 600px){
    .menu a {
        padding-right: .5rem;
        display: block;
        font-size: 33px;
        color: #fff;
        cursor: pointer;
    }
}