.Home {
    .fa-trash { display: none; }
  }
  
  .About {
    margin: 0 auto;
    max-width: 1024px;
  
    &-header {
      margin: 2rem auto;
      width: 80%;
      display: grid;
      grid-template-columns: 1fr repeat(2, 2rem) 1fr;
  
      img { border-radius: 50%; }
  
      img:first-child {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
      }
  
      img:last-child {
        grid-column: 2 / 5;
        grid-row: 1 / 2;
      }
    }
  
    &-info {
      @extend .u-message;
      width: 80%;
      margin: 4rem auto;
      text-align: left;
    }
  
    &-flow {
      width: 90%;
      margin: 2rem auto;
  
      div {
        margin-bottom: 4rem;
        display: grid;
        grid-template-columns: 3rem 1fr;
        grid-template-rows: repeat(2, auto);
        grid-template-areas:
          'h2 h3'
          '.  p';
        grid-gap: 2vw;
        align-items: baseline;
  
        @include media-queries(large) { grid-gap: 1rem; }
      }
  
      h2 {
        grid-area: h2;
        color: var(--title-color);
        font-size: 10vw;
        @include media-queries(large) { font-size: 5rem; }
      }
  
      h3 {
        grid-area: h3;
        color: var(--title-color);
        font-size: 5vw;
        @include media-queries(large) { font-size: 2.5rem; }
      }
  
      p {
        grid-area: p;
        font-size: 4vw;
        @include media-queries(small) { font-size: 3.5vw; }
        @include media-queries(medium) { font-size: 3vw; }
        @include media-queries(large) { font-size: 1.5rem; }
      }
    }
  }
  .Contact {
    margin: 0 auto;
    max-width: 100%;
    min-height: calc(100vh - var(--header-height));
    display: flex;
    justify-content: center;
    align-items: center;
  
    @include media-queries(medium) {
      min-height: calc(100vh - var(--header-height) - 2rem);
    }
  
    Form { width: 100%; }
  }
  
  .Admin {
    padding: 1rem;
    min-height: calc(100vh - var(--header-height));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    @include media-queries(medium) {
      min-height: calc(100vh - var(--header-height) - 2rem);
    }
  
    &-auth {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      > * { width: 80%; }
  
      @include media-queries(medium) {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: flex-start;
  
        > * { width: 40%; }
      }
    }
  
    &-user {
      display: flex;
      flex-direction: column;
      align-items: center;
  
      img {
        max-width: 150px;
        border-radius: 50%;
      }
    }
  }
  
  .Sign-btn {
    @extend .u-message;
    @extend .u-btn;
    max-width: 70%;
  }