.Footer{
    text-align: center;
    background-color: var(--second-modal-color);
    color:var(--alternate-color);
    @include media-queries(medium){
        display:none;
    }
    @include media-queries(large){
        display:none;
    }
}