.photos {
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(320px, 1fr));
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
    grid-gap: 1rem;
    border-radius: .3vw;
    @extend .u-responsive-flexbox;
    max-width: var(--max-width);
    margin-left: auto;
    margin-right: auto;
    figure {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      max-width: var(--max-width);
    }
    .fa-trash { cursor: pointer; }
  }
