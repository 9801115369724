.Slider{
    position: relative;
    top: 80px;
}

#wrapper{
    position: relative;
    width:100%;
    min-height:40vw;
    overflow: hidden;
  }
  
  .layer{
    position: absolute;
    width:100vw;
    min-height:40vw;
    overflow: hidden;
  }
  
  .layer .content-wrap{
    position: absolute;
    width:100vw;
    min-height:40vw;
  }
  .title-slider-left{
  color:#7e00ef;
  font-weight:500;
  text-transform: uppercase;
  }
  .title-slider-right{
    color:#ffffff;
    font-weight:500;
    text-transform: uppercase;
  }
    
  .title-slider{
  font-family: 'Roboto', 'Arial Narrow', Arial, sans-serif;
  font-weight: 300;
  }
  .paragraph-right,
  .paragraph-left{
  font-weight: 300;
  }
  .layer .content-body{
    width:25%;
    position:absolute;
    top:50%;
    text-align: center;
    transform:translateY(-50%);
    color:#fff;
  }
  
  .layer img{
    position: absolute;
    width:25%;
    top:50%;
    left: 50%;
    transform:translate(-50%, -50%);
  }
  
  .layer h1{
    font-size:2em;
  }
  
  .bottom{
    background:#ffaeae;
    z-index:1;
  }
  
  .bottom .content-body{
    right:5%;
  }
  .top{
    background:#eee;
    color:#222;
    z-index:2;
    width:50vw;
  }
  
  .top .content-body{
    left: 5%;
    color:#222;
  }
  
  .handle{
    position: absolute;
    height: 100%;
    display: block;
    background-color: #7e00ef;
    width:5px;
    top:0;
    left: 50%;
    z-index:3;
  }
  
  .skewed .handle{
    top:50%;
    transform:rotate(30deg) translateY(-50%);
    height: 200%;
    transform-origin:top;
  }
  
  .skewed .top{
    transform: skew(-30deg);
    margin-left:-1000px;
    width: calc(50vw + 1000px);
  }
  
  .skewed .top .content-wrap{
    transform: skew(30deg);
    margin-left:1000px;
  }
  
  @media(max-width:768px){
    body{
      font-size:75%;
    }
  }

  #wrapper{
	display: none;
	@include media-queries(extra-small) {
     display: none;
    }
    @include media-queries(small) {
     display: none;
    }
    @include media-queries(medium) {
     display: block;
    }
    @include media-queries(large) {
     display: block;
    }
	    @include media-queries(extra-large) {
     display: block;
    }
  }