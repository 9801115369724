.title{
    margin: 2rem 0;
    font-size: .5vw;
    font-family: var(--main-font);
    color:var(--main-color);
    @include media-queries(medium){
        align-self: center;
        margin: 0;
        font-size: 1.5vw;
        text-align: center;
        grid-area: title;
    }
    @include media-queries(large){
        margin: 1rem 0;
        text-align: initial;
        flex-grow: 1;
    }
    *{
        // margin-bottom: .5rem;
        @include media-queries(medium){
            margin-bottom: 0;
            display: none;
        }
        @include media-queries(large){
            margin-bottom: 0;
            display: none;
        }
    }
    small{text-transform: uppercase;}
}