// .items {
//     display: grid;
//     grid-template-columns: repeat(3, 1fr);
//     box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
//   }
  
  .item {
    position: relative;
    background:#fff;
    overflow: hidden;
    border-radius: .3vw;
  
    &::after {
      content: '';
      position: absolute;
      display: block;
      background: inherit;
      opacity: 0.9;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
      transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
    }

    // Bring in main color overlay
    &:hover:after {
        background:#7e00ef;
      transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
    }
  
    // Scale image on hover
    &:hover &-image {
      transform: scale(1.2);
    }
  
    // Bring in text on hover
    &:hover &-text {
      opacity: 1;
      transform: translateY(0);
    }
  
    &-image {
      height: auto;
      transform: translateZ(0);
      display: block;
      transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
    }
  
    &-image:before {
      content: '';
      display: block;
      padding-top: 75%;
      overflow: hidden;
    }
  
    &-image img {
      position: absolute;
      top: 0;
      left: 15%;
      width: 74%;
      padding: 2%;
      height: auto;
      line-height: 0;
    }
  
    &-text {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      text-align: center;
      z-index: 1;
      color: #fff;
      transform: translateY(-20%);
      transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),
        transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
      transition-delay: 300ms;
    }
  
    &-text-wrap {
      position: absolute;
      width: 100%;
      top: 50%;
      padding: 5%;
      transform: translateY(-50%);
    }
  
    &-text-title {
      font-size: 2rem;
      padding: 0 1rem;
      margin: 5px 0 0 0;
    }
  
    &-text-category {
      text-transform: uppercase;
      font-size: 1.2rem;
      opacity: 0.7;
      margin: 0;
    }
  }
  .comment{
font-size: 12px;
  }


.main-about{
  display: grid;
  margin-top: 35px;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
  grid-gap: 1rem;
  border-radius: .3vw;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;

  @include media-queries(medium){
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
}
}
.card-about{
  padding: 3%;
  &__img img{
    width: 50%;
    margin: auto;
    display: block;
  }
  &__content{
      padding: 1rem;
      background: #fff;
  }
  &__title{
    font-family:$roboto-sans;
    font-weight: 300;
    font-size: 22px;
  }
  &__subtitle{
    font-family:$roboto-sans;
      margin-top: .1rem;
      margin-bottom: .5rem;
      opacity: .6;
      font-weight: 400;
      font-size: 14px;
      text-align: justify;
  }
  &__footer{
    font-family:$roboto-sans;
      border-top:1px solid var(--color-border);
      margin-top:1rem;
      padding-top: 1rem;
      display:flex;
      align-items: center;
      justify-content: space-between;
  }
}
