@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,700i,900&subset=latin-ext");

:root {
  --main-font: 'Roboto', sans-serif;
  --alternate-font: sans-serif;
  --font-size: 16px;
  --font-weight-300: 100;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-700: 700;
  --line-height: auto;
  --main-color: #FFF;
  --color-hover: #6f007b;
  --color-admin: #e4e4e4;
  --main-modal-color: rgb(255, 255, 255, .75);
  --second-color: #000;
  --three-color: #fbfbfb;
  --second-modal-color: rgba(0, 0, 0, .75);
  --alternate-color: rgb(190, 0, 143);
  --alternate-modal-color: rgba(68, 0, 95, 0.75);
  --bg-color: var(--main-color);
  --bg-modal-color: var(--main-modal-color);
  --bg-alternate-color: #999;
  --bg-alternate-modal-color: rgba(153, 153, 153, .75);
  --border-color: #DDD;
  --text-color: #242424;
  --title-color: #333;
  --link-color: var(--alternate-color);
  --link-hover-color: var(--alternate-modal-color);
  --max-width: 1600px;
  --header-height: 4rem;
  --logo-width: 4rem;
  --logo-height: 4rem;
  --logo-spacing: .5rem;
  --z-back: -10;
  --z-standar: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

html {
  box-sizing: border-box;
  font-family: var(--main-font),var(--alternate-font);
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--text-color);
  background-color: var(--bg-modal-color);
}

*,
*:after,
*:before {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

body {
  overflow-x: hidden;
}

a {
  font-weight: var(--font-weight-400);
  text-decoration: none;
  color: var(--link-color);
  transition: all .3s ease-out;
}

@media screen and (min-width: 37.5em) {
  a a {
    font-weight: var(--font-weight-400);
    text-decoration: none;
    color: var(--link-color);
    transition: all .3s ease-out;
  }

  a a:hover {
    color: var(--link-hover-color);
  }
}

@media screen and (min-width: 64em) {
  a:hover {
    color: var(--link-hover-color);
  }
}

img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

::selection {
  background-color: var(--main-color);
  color: var(--second-color);
}

.u-responsive-grid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.u-responsive-flexbox,
.photos {
  display: flex;
  flex-wrap: wrap;
}

.u-responsive-flexbox>*,
.photos>* {
  flex: 1 1 200px;
}

.u-message,
.About-info,
.Sign-btn,
.Form input[type="submit"],
.Form progress+span {
  font-family: "Roboto",sans-serif;
  font-size: 4vw;
  font-weight: 300;
  text-align: center;
}

@media screen and (min-width: 45em) {
  .u-message,
  .About-info,
  .Sign-btn,
  .Form input[type="submit"],
  .Form progress+span {
    font-size: 1.5rem;
  }
}

.u-none {
  display: none;
}

.u-btn,
.Sign-btn,
.Form input[type="submit"] {
  margin: 1rem auto;
  padding: .5rem 1rem;
  outline: 0;
  border: 0;
  font-family: "Roboto",sans-serif;
  border-radius: 50px;
  background-color: rgba(183,0,255,0.75);
  font-size: 23px;
  color: #fff;
  font-weight: 300;
  cursor: pointer;
  transition: all .3s ease;
}

.u-btn:hover,
.Sign-btn:hover,
.Form input:hover[type="submit"] {
  background-color: var(--alternate-color);
}

.main-grid {
  margin-top: 49px;
}

.l-container {
  max-width: 1200px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.l-section {
  margin-bottom: 2rem;
}

@media screen and (min-width: 64em) {
  .l-section {
    margin-bottom: 4rem;
  }
}

.l-section--diagonal {
  position: relative;
}

.l-section--diagonal:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: var(--z-back);
  background-color: #ff95f3;
  background: linear-gradient(150deg, #7e00ef 15%, rgba(247,212,255,0.380392) 70%, rgba(255,255,255,0.341176) 94%);
  transform: skewY(-1deg) scaleY(1.05);
}

@media screen and (min-width: 64em) {
  .l-section--diagonal:before {
    transform: skewY(-1.4deg) scaleY(1.1);
  }
}

.section-titles {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.section-title {
  font-family: "Roboto",sans-serif;
  font-size: 49px;
  font-weight: 300;
}

.section-description {
  font-family: "Roboto",sans-serif;
  text-align: justify;
  font-size: 17px;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.text {
  text-align: justify;
  font-size: 15px;
  font-family: "Roboto",sans-serif;
}

.small-5 {
  width: 5%;
}

.small-10 {
  width: 10%;
}

.small-15 {
  width: 15%;
}

.small-20 {
  width: 20%;
}

.small-25 {
  width: 25%;
}

.small-30 {
  width: 30%;
}

.small-35 {
  width: 35%;
}

.small-40 {
  width: 40%;
}

.small-45 {
  width: 45%;
}

.small-50 {
  width: 50%;
}

.small-55 {
  width: 55%;
}

.small-60 {
  width: 60%;
}

.small-65 {
  width: 65%;
}

.small-70 {
  width: 70%;
}

.small-75 {
  width: 75%;
}

.small-80 {
  width: 80%;
}

.small-85 {
  width: 85%;
}

.small-90 {
  width: 90%;
}

.small-95 {
  width: 95%;
}

.small-100 {
  width: 100%;
}

@media screen and (min-width: 64em) {
  .large-5 {
    width: 5%;
  }

  .large-10 {
    width: 10%;
  }

  .large-15 {
    width: 15%;
  }

  .large-20 {
    width: 20%;
  }

  .large-25 {
    width: 25%;
  }

  .large-30 {
    width: 30%;
  }

  .large-35 {
    width: 35%;
  }

  .large-40 {
    width: 40%;
  }

  .large-45 {
    width: 45%;
  }

  .large-50 {
    width: 50%;
  }

  .large-55 {
    width: 55%;
  }

  .large-60 {
    width: 60%;
  }

  .large-65 {
    width: 65%;
  }

  .large-70 {
    width: 70%;
  }

  .large-75 {
    width: 75%;
  }

  .large-80 {
    width: 80%;
  }

  .large-85 {
    width: 85%;
  }

  .large-90 {
    width: 90%;
  }

  .large-95 {
    width: 95%;
  }

  .large-100 {
    width: 100%;
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #7e00ef;
  margin-top: 15px;
  padding: 5px;
}

.footer-title {
  font-weight: 300;
  color: #fff;
  font-size: 15px;
}

.footer-year {
  font-weight: 300;
  color: #fff;
  font-size: 15px;
}

.Home .fa-trash {
  display: none;
}

.About {
  margin: 0 auto;
  max-width: 1024px;
}

.About-header {
  margin: 2rem auto;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr repeat(2, 2rem) 1fr;
}

.About-header img {
  border-radius: 50%;
}

.About-header img:first-child {
  grid-column: 1 / 4;
  grid-row: 1 / 2;
}

.About-header img:last-child {
  grid-column: 2 / 5;
  grid-row: 1 / 2;
}

.About-info {
  width: 80%;
  margin: 4rem auto;
  text-align: left;
}

.About-flow {
  width: 90%;
  margin: 2rem auto;
}

.About-flow div {
  margin-bottom: 4rem;
  display: grid;
  grid-template-columns: 3rem 1fr;
  grid-template-rows: repeat(2, auto);
  grid-template-areas: 'h2 h3' '.  p';
  grid-gap: 2vw;
  align-items: baseline;
}

@media screen and (min-width: 64em) {
  .About-flow div {
    grid-gap: 1rem;
  }
}

.About-flow h2 {
  grid-area: h2;
  color: var(--title-color);
  font-size: 10vw;
}

@media screen and (min-width: 64em) {
  .About-flow h2 {
    font-size: 5rem;
  }
}

.About-flow h3 {
  grid-area: h3;
  color: var(--title-color);
  font-size: 5vw;
}

@media screen and (min-width: 64em) {
  .About-flow h3 {
    font-size: 2.5rem;
  }
}

.About-flow p {
  grid-area: p;
  font-size: 4vw;
}

@media screen and (min-width: 37.5em) {
  .About-flow p {
    font-size: 3.5vw;
  }
}

@media screen and (min-width: 45em) {
  .About-flow p {
    font-size: 3vw;
  }
}

@media screen and (min-width: 64em) {
  .About-flow p {
    font-size: 1.5rem;
  }
}

.Contact {
  margin: 0 auto;
  max-width: 100%;
  min-height: calc(100vh - var(--header-height));
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 45em) {
  .Contact {
    min-height: calc(100vh - var(--header-height) - 2rem);
  }
}

.Contact Form {
  width: 100%;
}

.Admin {
  padding: 1rem;
  min-height: calc(100vh - var(--header-height));
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 45em) {
  .Admin {
    min-height: calc(100vh - var(--header-height) - 2rem);
  }
}

.Admin-auth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Admin-auth>* {
  width: 80%;
}

@media screen and (min-width: 45em) {
  .Admin-auth {
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .Admin-auth>* {
    width: 40%;
  }
}

.Admin-user {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Admin-user img {
  max-width: 150px;
  border-radius: 50%;
}

.Sign-btn {
  max-width: 70%;
}

.Header {
  position: fixed;
  z-index: var(--z-standar);
  top: 0;
  padding: .5rem;
  padding-top: var(--header-height);
  margin-left: -100vw;
  width: 50%;
  height: 100vh;
  overflow-y: auto;
  transition: margin-left .3s ease;
  width: 60%;
  height: 100vh;
  display: flex;
  z-index: 10000;
  flex-direction: column;
  background-color: #000000c7;
}

.Header.is-active {
  margin-left: 0;
}

@media screen and (min-width: 45em) {
  .Header {
    margin-left: 0;
    padding-top: 0;
    padding: .5rem;
    width: 100%;
    height: auto;
    display: grid;
    background-color: var(--three-color);
    grid-template-areas: "logo title menu";
    grid-template-columns: var(--header-height) 1fr 1fr;
    grid-gap: 1vw;
  }
}

.Header-btn {
  position: fixed;
  display: flex;
  align-items: center;
  z-index: var(--z-tooltip);
  top: var(--logo-spacing);
  right: var(--logo-spacing);
  width: var(--logo-width);
  height: var(--logo-height);
  overflow-y: auto;
  transition: .3s ease;
}

@media screen and (min-width: 45em) {
  .Header-btn {
    display: none;
  }
}

.Header-btn.hamburger:focus {
  outline: 0;
  border: 0;
}

.Header-btn .hamburger-inner,
.Header-btn .hamburger-inner::after,
.Header-btn .hamburger-inner::before {
  background-color: var(--link-color);
  width: 100%;
}

.logo {
  position: fixed;
  z-index: 999;
  top: var(--logo-spacing);
  left: var(--logo-spacing);
  padding: var(--logo-spacing);
  width: var(--logo-width);
  height: var(--logo-height);
}

@media screen and (min-width: 45em) {
  .logo {
    position: static;
    width: var(--header-height);
    height: var(--header-height);
    grid-area: logo;
    background-color: transparent;
    border-radius: 0;
  }
}

.title {
  margin: 2rem 0;
  font-size: .5vw;
  font-family: var(--main-font);
  color: var(--main-color);
}

@media screen and (min-width: 45em) {
  .title {
    align-self: center;
    margin: 0;
    font-size: 1.5vw;
    text-align: center;
    grid-area: title;
  }
}

@media screen and (min-width: 64em) {
  .title {
    margin: 1rem 0;
    text-align: initial;
    flex-grow: 1;
  }
}

@media screen and (min-width: 45em) {
  .title * {
    margin-bottom: 0;
    display: none;
  }
}

@media screen and (min-width: 64em) {
  .title * {
    margin-bottom: 0;
    display: none;
  }
}

.title small {
  text-transform: uppercase;
}

.menu {
  margin-bottom: 2rem;
  text-align: right;
  margin-bottom: 2rem;
}

@media screen and (min-width: 37.5em) {
  .menu {
    font-size: 2vw;
    color: #fff;
  }
}

@media screen and (min-width: 45em) {
  .menu {
    font-size: 1.2vw;
    grid-area: menu;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 45em) {
  .menu ul {
    margin-bottom: 0;
    align-items: center;
    align-content: space-between;
    display: flex;
  }
}

@media screen and (min-width: 64em) {
  .menu ul {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
  }
}

.menu li {
  list-style: none;
}

.menu .admin {
  color: var(--color-admin);
}

.menu a {
  padding-right: .5rem;
  display: block;
}

.menu a:hover {
  color: var(--main-color);
  background-color: var(--alternate-color);
}

@media screen and (min-width: 30em) {
  .menu a:hover {
    background-color: transparent;
    color: #fff;
    color: var(--color-hover);
    font-size: 24px;
  }
}

@media screen and (min-width: 37.5em) {
  .menu a:hover {
    background-color: transparent;
    color: #fff;
    color: var(--color-hover);
    font-size: 24px;
  }
}

@media screen and (min-width: 45em) {
  .menu a:hover {
    background-color: transparent;
    color: #fff;
    color: var(--color-hover);
  }
}

@media screen and (min-width: 45em) {
  .menu a:hover {
    background-color: transparent;
    color: var(--color-hover);
  }
}

@media screen and (min-width: 64em) {
  .menu a:hover {
    background-color: transparent;
  }
}

@media (max-width: 600px) {
  .menu a {
    padding-right: .5rem;
    display: block;
    font-size: 33px;
    color: #fff;
    cursor: pointer;
  }
}

.item {
  position: relative;
  background: #fff;
  overflow: hidden;
  border-radius: .3vw;
}

.item::after {
  content: '';
  position: absolute;
  display: block;
  background: inherit;
  opacity: 0.9;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(2) translateX(-75%) translateY(-75%) rotate(-28deg);
  transition: transform 3s cubic-bezier(0.2, 1, 0.3, 1);
}

.item:hover:after {
  background: #7e00ef;
  transform: scale(2) translateX(0) translateY(0) rotate(-28deg);
}

.item:hover .item-image {
  transform: scale(1.2);
}

.item:hover .item-text {
  opacity: 1;
  transform: translateY(0);
}

.item-image {
  height: auto;
  transform: translateZ(0);
  display: block;
  transition: transform 750ms cubic-bezier(0.2, 1, 0.3, 1);
}

.item-image:before {
  content: '';
  display: block;
  padding-top: 75%;
  overflow: hidden;
}

.item-image img {
  position: absolute;
  top: 0;
  left: 15%;
  width: 74%;
  padding: 2%;
  height: auto;
  line-height: 0;
}

.item-text {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  text-align: center;
  z-index: 1;
  color: #fff;
  transform: translateY(-20%);
  transition: opacity 500ms cubic-bezier(0.2, 1, 0.3, 1),transform 500ms cubic-bezier(0.2, 1, 0.3, 1);
  transition-delay: 300ms;
}

.item-text-wrap {
  position: absolute;
  width: 100%;
  top: 50%;
  padding: 5%;
  transform: translateY(-50%);
}

.item-text-title {
  font-size: 2rem;
  padding: 0 1rem;
  margin: 5px 0 0 0;
}

.item-text-category {
  text-transform: uppercase;
  font-size: 1.2rem;
  opacity: 0.7;
  margin: 0;
}

.comment {
  font-size: 12px;
}

.main-about {
  display: grid;
  margin-top: 35px;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
  grid-gap: 1rem;
  border-radius: .3vw;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
}

@media screen and (min-width: 45em) {
  .main-about {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}

.card-about {
  padding: 3%;
}

.card-about__img img {
  width: 50%;
  margin: auto;
  display: block;
}

.card-about__content {
  padding: 1rem;
  background: #fff;
}

.card-about__title {
  font-family: "Roboto",sans-serif;
  font-weight: 300;
  font-size: 22px;
}

.card-about__subtitle {
  font-family: "Roboto",sans-serif;
  margin-top: .1rem;
  margin-bottom: .5rem;
  opacity: .6;
  font-weight: 400;
  font-size: 14px;
  text-align: justify;
}

.card-about__footer {
  font-family: "Roboto",sans-serif;
  border-top: 1px solid var(--color-border);
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.l-container {
  max-width: var(--max-width);
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.main-banner-content {
  text-align: center;
}

.main-banner {
  text-align: center;
  --padding: 2rem 1rem 3rem;
  --logo-height: 4rem;
  padding: var(--padding);
  background-color: var(--color-first);
  color: #fff;
  text-align: center;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
  position: relative;
}

@media screen and (min-width: 64em) {
  .main-banner {
    --padding: 8rem 1rem 12rem;
    --logo-height: 5rem;
  }
}

.main-banner__content {
  position: relative;
}

.main-banner__logo {
  height: var(--logo-height);
  display: block;
  margin: 0 auto .5rem;
}

.main-banner__title {
  font-family: "Roboto",sans-serif;
  font-size: 49px;
  font-weight: 300;
  text-shadow: 1px 1px 4px rgba(0,0,0,0.43);
}

.main-banner__banner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.main-banner__description {
  font-size: 23px;
  font-family: "Roboto",sans-serif;
  text-shadow: 1px 1px 4px rgba(0,0,0,0.43);
}

.main-banner-title {
  font-family: "Roboto",sans-serif;
  font-size: 49px;
  font-weight: 300;
}

.Slider {
  position: relative;
  top: 80px;
}

#wrapper {
  position: relative;
  width: 100%;
  min-height: 40vw;
  overflow: hidden;
}

.layer {
  position: absolute;
  width: 100vw;
  min-height: 40vw;
  overflow: hidden;
}

.layer .content-wrap {
  position: absolute;
  width: 100vw;
  min-height: 40vw;
}

.title-slider-left {
  color: #7e00ef;
  font-weight: 500;
  text-transform: uppercase;
}

.title-slider-right {
  color: #ffffff;
  font-weight: 500;
  text-transform: uppercase;
}

.title-slider {
  font-family: 'Roboto', 'Arial Narrow', Arial, sans-serif;
  font-weight: 300;
}

.paragraph-right,
.paragraph-left {
  font-weight: 300;
}

.layer .content-body {
  width: 25%;
  position: absolute;
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
  color: #fff;
}

.layer img {
  position: absolute;
  width: 25%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.layer h1 {
  font-size: 2em;
}

.bottom {
  background: #ffaeae;
  z-index: 1;
}

.bottom .content-body {
  right: 5%;
}

.top {
  background: #eee;
  color: #222;
  z-index: 2;
  width: 50vw;
}

.top .content-body {
  left: 5%;
  color: #222;
}

.handle {
  position: absolute;
  height: 100%;
  display: block;
  background-color: #7e00ef;
  width: 5px;
  top: 0;
  left: 50%;
  z-index: 3;
}

.skewed .handle {
  top: 50%;
  transform: rotate(30deg) translateY(-50%);
  height: 200%;
  transform-origin: top;
}

.skewed .top {
  transform: skew(-30deg);
  margin-left: -1000px;
  width: calc(50vw + 1000px);
}

.skewed .top .content-wrap {
  transform: skew(30deg);
  margin-left: 1000px;
}

@media (max-width: 768px) {
  body {
    font-size: 75%;
  }
}

#wrapper {
  display: none;
}

@media screen and (min-width: 30em) {
  #wrapper {
    display: none;
  }
}

@media screen and (min-width: 37.5em) {
  #wrapper {
    display: none;
  }
}

@media screen and (min-width: 45em) {
  #wrapper {
    display: block;
  }
}

@media screen and (min-width: 64em) {
  #wrapper {
    display: block;
  }
}

@media screen and (min-width: 75em) {
  #wrapper {
    display: block;
  }
}

.social-media {
  font-size: 25px;
  display: block;
  display: flex;
  align-items: center;
  margin-bottom: 17px;
  justify-content: flex-start;
}

@media screen and (min-width: 30em) {
  .social-media {
    justify-content: center;
    font-size: 30px;
  }
}

@media screen and (min-width: 37.5em) {
  .social-media {
    justify-content: center;
    font-size: 30px;
  }
}

@media screen and (min-width: 45em) {
  .social-media {
    justify-content: flex-start;
    font-size: 25px;
  }
}

@media screen and (min-width: 64em) {
  .social-media {
    justify-content: flex-start;
    font-size: 25px;
  }
}

@media screen and (min-width: 75em) {
  .social-media {
    justify-content: flex-start;
    font-size: 25px;
  }
}

.social-media a {
  padding-left: 20px;
}

.Footer {
  text-align: center;
  background-color: var(--second-modal-color);
  color: var(--alternate-color);
}

@media screen and (min-width: 45em) {
  .Footer {
    display: none;
  }
}

@media screen and (min-width: 64em) {
  .Footer {
    display: none;
  }
}

.Main {
  padding: 0 .5rem;
  padding-left: 0;
  padding-top: var(--header-height);
  width: 100%;
  padding-right: 0;
  min-height: 100vh;
  transition: margin-left .3s ease, width .3s ease;
}

.Main.is-active {
  width: 100%;
}

@media screen and (min-width: 45em) {
  .Main {
    padding-top: calc(var(--header-height) + 1rem);
  }
}

.Form * {
  margin-bottom: .5rem;
}

.Form input[type="text"],
.Form input[type="email"],
.Form textarea {
  padding: .5rem;
  width: 100%;
  display: block;
  border: thin solid var(--alternate-color);
  font-size: 1rem;
  font-family: "Roboto",sans-serif;
  transition: all .3s ease-out;
  padding: .5rem;
  width: 100%;
  height: 40px;
  border-radius: 30px;
  font-family: "Roboto",sans-serif;
  transition: all .3s ease-out;
  outline: none;
}

.Form progress {
  margin-bottom: 1rem;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  width: 100%;
  height: 1.5rem;
}

.Form progress[value] {
  background-color: var(--alternate-color);
}

.Form progress::-webkit-progress-bar {
  background-color: var(--alternate-color);
}

.Form progress::-moz-progress-bar {
  background-color: var(--second-color);
}

.Form progress::-webkit-progress-value {
  background-color: var(--second-color);
}

.Form progress+span {
  display: block;
}

.Form-uploader {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Form-uploader span {
  padding-left: .5rem;
  width: 15%;
  display: block;
  text-align: center;
}

.Form-uploader input[type="file"] {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  opacity: 0;
}

.container-contact {
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
  margin: auto;
  border-radius: 10px;
}

.photos {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.3);
  grid-gap: 1rem;
  border-radius: .3vw;
  max-width: var(--max-width);
  margin-left: auto;
  margin-right: auto;
}

.photos figure {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
}

.photos .fa-trash {
  cursor: pointer;
}

.l-container {
  max-width: 1200px;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.center-block {
  margin-left: auto;
  margin-right: auto;
}

.center-text {
  text-align: center;
}

.small-5 {
  width: 5%;
}

.small-10 {
  width: 10%;
}

.small-15 {
  width: 15%;
}

.small-20 {
  width: 20%;
}

.small-25 {
  width: 25%;
}

.small-30 {
  width: 30%;
}

.small-35 {
  width: 35%;
}

.small-40 {
  width: 40%;
}

.small-45 {
  width: 45%;
}

.small-50 {
  width: 50%;
}

.small-55 {
  width: 55%;
}

.small-60 {
  width: 60%;
}

.small-65 {
  width: 65%;
}

.small-70 {
  width: 70%;
}

.small-75 {
  width: 75%;
}

.small-80 {
  width: 80%;
}

.small-85 {
  width: 85%;
}

.small-90 {
  width: 90%;
}

.small-95 {
  width: 95%;
}

.small-100 {
  width: 100%;
}

@media screen and (min-width: 64em) {
  .large-5 {
    width: 5%;
  }

  .large-10 {
    width: 10%;
  }

  .large-15 {
    width: 15%;
  }

  .large-20 {
    width: 20%;
  }

  .large-25 {
    width: 25%;
  }

  .large-30 {
    width: 30%;
  }

  .large-35 {
    width: 35%;
  }

  .large-40 {
    width: 40%;
  }

  .large-45 {
    width: 45%;
  }

  .large-50 {
    width: 50%;
  }

  .large-55 {
    width: 55%;
  }

  .large-60 {
    width: 60%;
  }

  .large-65 {
    width: 65%;
  }

  .large-70 {
    width: 70%;
  }

  .large-75 {
    width: 75%;
  }

  .large-80 {
    width: 80%;
  }

  .large-85 {
    width: 85%;
  }

  .large-90 {
    width: 90%;
  }

  .large-95 {
    width: 95%;
  }

  .large-100 {
    width: 100%;
  }
}

