.l-container{
    max-width: var(--max-width);
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
}
.main-banner-content{
    text-align: center;
}
.main-banner{
    text-align: center;
    --padding:2rem 1rem 3rem;
    --logo-height:4rem;
    @include media-queries(large){
    --padding:8rem 1rem 12rem;
    --logo-height:5rem;
    }
    padding:var(--padding);
    background-color:var(--color-first);
    color:#fff;
    text-align: center;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
    position: relative;
    &__content{
        position: relative;
    }
    &__logo{
        height: var(--logo-height);
        display: block;
        margin:  0 auto .5rem;
    }
    &__title{
        font-family: "Roboto",sans-serif;
        font-size: 49px;
        font-weight: 300;
        text-shadow: 1px 1px 4px rgba(0,0,0,0.43);
    }
    &__banner{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
        // opacity: .1;
    }
    &__description{
    font-size: 23px;
    font-family: "Roboto",sans-serif;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.43);
    }

}
.main-banner-title{
    font-family: "Roboto",sans-serif;
    font-size: 49px;
    font-weight: 300;
}

