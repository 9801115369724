.Main {
    padding: 0 .5rem;
    padding-left: 0;
    padding-top: var(--header-height);
    width: 100%;
    padding-right: 0;
    min-height: 100vh;
    transition:
      margin-left .3s ease,
      width .3s ease;
  
    &.is-active {
      width: 100%;
    }
  
    @include media-queries(medium) {
      padding-top: calc(var(--header-height) + 1rem);
    }
  
    // @include media-queries(large) {
    //   padding: 1rem;
    //   margin-left: 30%;
    //   width: 70%;
    // }
  }